import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Image from '../../components/image'
import { Link } from 'gatsby'

const KapilendoPage = () => (
  <Layout>
    <SEO
      title="Kapilendo"
      description="Read the case study of Kapilendo, a UX project for a P2P credit platform."
    />
    <section>
      <hgroup>
        <h6>2017</h6>
        <h2>Kapilendo</h2>
      </hgroup>
      <div className="project-header-image">
        <Image filename="kapilendo-dashboard" />
      </div>
      <div className="section-columns">
        <div>
          <h3>Overview</h3>
          <p>
            In 2017 I had the pleasure to work on a freelance project for
            Kapilendo. Kapilendo is an investment platform for P2P business
            credits. Back then, Kapilendo was still divided into credits and
            ventures. The goal was to unify the platform and also implement new
            features to the existing application.
          </p>
          <p>
            I was responsible for creating a new user interface design for the
            application update and also for UX design consultation. As I didn’t
            have access to user feedback, I implemented a continuous feedback
            loop with all stakeholders from Kapilendo to build the best possible
            solution for them. The involvement of the Founder team helped to get
            information from the business and development perspective.
          </p>
          <p>
            During the project I’ve created different prototypes and used the
            feedback to iterate the design until we met all requirements of the
            new features.
          </p>
        </div>
        <div>
          <h3>Challenge</h3>
          <p>
            The main screen for the user was the portfolio. Kapilendo wanted to
            add a new notification and messages area to inform the user about
            updates, new projects and payments. There were different message
            types, which led to different sections in the application. Moreover
            a payment overview needed to be implemented to see all recent
            payment activity of the investments.
          </p>
          <p>
            Besides the application Kapilendo wanted to optimise the onboarding
            experience, to increase user registrations. For the project overview
            they used a card layout to showcase each project. The cards also
            needed a redesign, as they wanted to emphasise the asset class and
            also streamline the layout across different investment types.
          </p>
        </div>
      </div>
      <h3 className="text-center">Redesign of the cards</h3>
      <div className="image-container">
        <Image filename="kapilendo-cards-old" />
      </div>
      <div className="image-container">
        <Image filename="kapilendo-cards-new" />
      </div>
      <div className="section-columns">
        <div>
          <h3>Approach</h3>
          <p>
            The team of Kapilendo delivered a simple wireframe of the new
            portfolio, which helped to build the new design. As it was my first
            P2P project, I also did some research on other platforms in the
            market to get more insights and best practices.
          </p>
          <p>
            A comprehensive evaluation of the onboarding process from the UX
            perspective also helped the team to optimise the flow and
            experience.
          </p>
        </div>
        <div>
          <h3>Implementation</h3>
          <p>
            During the project I was responsible for the ux/ui design and
            created different prototypes of the portfolio screen. Using click
            dummies, I was able to get fast feedback from the team and iterate
            on the new application style. Creating a style guide helped to
            extend the new style to all the other parts of the existing
            application.
          </p>
          <p>
            For the project cards I’ve created a simple, but modular card style.
            This helped to have a cleaner overview of the existing projects.
          </p>
        </div>
      </div>
      <div className="image-container">
        <Image filename="kapilendo-makler" />
      </div>
      <div className="image-container">
        <Image filename="kapilendo-portfolio" />
      </div>
    </section>
    <div className="cta-section">
      <section>
        <h2>Do you have a product vision?</h2>
        <p>
          I've helped more than <strong>60+ products</strong> to realise their
          vision in a market ready product. <br /> I would be happy to help you,
          developing your digital product from scratch and get it live.
        </p>
        <a href="https://calendly.com/dannygiebe/connect" className="btn">
          Let's talk about your product
        </a>
      </section>
    </div>
    <section>
      <div className="similar">
        <div>
          <Link to="/project/mountlytics/">Read MountLytics study</Link>
        </div>
        <div className="text-center">
          <Link to="/">Go to homepage</Link>
        </div>
        <div className="text-right">
          <Link to="/project/goretex/">Read Gore-Tex study</Link>
        </div>
      </div>
    </section>
  </Layout>
)
export default KapilendoPage
